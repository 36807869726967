[class^="cve-browser-spinner"] {
   height: calc( 100vh - var(--navbar-height) );
   background-image: url(/src/images/Radar-Spinner.svg);
   background-repeat: no-repeat;
   background-position: center;
   background-size: 4em;
}

[class^="cve-browser-component-"] {
   display: grid;
   grid-template-columns: auto;
}

[class^="cve-browser-component-"] [class^="tk-widget-"] {
   font-size: 1em;
}

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5) { [class^="cve-browser-component-"] [class^="tk-widget-"] { font-size: 0.8em } }
@media only screen and (-moz-min-device-pixel-ratio: 1.5) { [class^="cve-browser-component-"] [class^="tk-widget-"] { font-size: 0.8em } }
@media only screen and (-o-min-device-pixel-ratio: 3/2) { [class^="cve-browser-component-"] [class^="tk-widget-"] { font-size: 0.8em } }
@media only screen and (min-device-pixel-ratio: 1.5) { [class^="cve-browser-component-"] [class^="tk-widget-"] { font-size: 0.8em } }

/*[class^="cve-browser-component-"] > .asset-group {
   max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 3);
}*/

[class^="cve-browser-component-"] > .available-filters {
   max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2 );
}

[class^="cve-browser-component-"] > .selected-filters {
   max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2 );
}

[class^="cve-browser-component-"] > .cve-list {
   min-height: 0;
   min-width: 0;
   max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2);
}

[class^="cve-browser-component-"] > .cve-detail {
   min-height: 0;
   max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2);
}


@media only screen and (min-width: 1270px) {

   [class^="cve-browser-component-"] {
      grid-template-columns: minmax(163px, 1fr) minmax(163px, 1fr) minmax(163px, 1fr) minmax(163px, 1fr) minmax(163px, 1fr) minmax(163px, 1fr);
      grid-template-rows: auto auto 1fr;
      height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )));
   }

   /*[class^="cve-browser-component-"] > .asset-group {
      grid-column: 1/7;
      max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2.5);
   }*/

   [class^="cve-browser-component-"] > .available-filters {
      grid-column: 1/4;
      max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2.5);
   }

   [class^="cve-browser-component-"] > .selected-filters {
      grid-column: 4/7;
      max-height: calc( (100vh - var(--navbar-height) - (2 * var(--content-padding) )) / 2.5);
   }

   [class^="cve-browser-component-"] > .cve-list {
      grid-row: 2/4;
      grid-column: 1/5;
      max-height: unset;
   }

   [class^="cve-browser-component-"] > .cve-detail {
      grid-row: 2/4;
      grid-column: 5/9;
      max-height: unset;
   }
}


@media only screen and (min-width: 2375px) {

   [class^="cve-browser-component-"] {
      grid-template-columns: minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(250px, 1fr) minmax(500px, 1fr);
      grid-template-rows: auto auto 1fr;
   }

   /*[class^="cve-browser-component-"] > .asset-group {
      grid-column: 1/7;
   }*/

   [class^="cve-browser-component-"] > .available-filters {
      grid-column: 1/4;
   }

   [class^="cve-browser-component-"] > .selected-filters {
      grid-column: 4/7;
   }

   [class^="cve-browser-component-"] > .cve-list {
      grid-row: 2/4;
      grid-column: 1/7;
   }

   [class^="cve-browser-component-"] > .cve-detail {
      grid-row: 1/4;
      grid-column: 7/9;
   }

}



















