@media only screen and (-Webkit-min-device-pixel-ratio: 1.5) { .tk-navbar { font-size: 0.8em } }
@media only screen and (-moz-min-device-pixel-ratio: 1.5) { .tk-navbar { font-size: 0.8em } }
@media only screen and (-o-min-device-pixel-ratio: 3/2) { .tk-navbar { font-size: 0.8em } }
@media only screen and (min-device-pixel-ratio: 1.5) { .tk-navbar { font-size: 0.8em } }

.navbar-items {
   display: grid;
   width: calc(100vw - 2em);
   grid-template-columns: 0.0em auto 20em 2em 2em 2em 0.0em;
   white-space: nowrap;
}

.navbar-items .icon {
   transition: var(--transition-time-x-fast);
   border: solid 1px transparent
}

.navbar-items .icon:hover {
   transform: scale(1.25);
}

.navbar-item-logo {
   grid-column: 2;
   align-self: center;
   justify-self: left;
}

.navbar-item-logo > .logo {
   width: 8.5em;
   vertical-align: middle;
}

.navbar-item-company {
   grid-column: 3;
   justify-self: right;
   align-self: flex-end;
   margin-top: calc(var(--spacing-small) * -1);
}

.navbar-item-icon-settings {
   grid-column: 4;
   align-self: center;
   vertical-align: bottom;
}

.navbar-item-icon-settings .icon {
   width: 1.20em;
   height: 1.20em;
   background-color: var(--navbar-color);
   color: var(--navbar-color);
   mask-image: url(images/Settings.svg);
   mask-position: center;
   mask-repeat: no-repeat;
   mask-size: 1em;
   margin-left: var(--spacing-small);
}

.navbar-item-icon-user {
   grid-column: 5;
   align-self: center;
   vertical-align: bottom;
}

.navbar-item-icon-user .icon {
   width: 1.20em;
   background-color: var(--navbar-color);
   mask-image: url(images/User.svg);
   mask-position: center;
   mask-repeat: no-repeat;
   mask-size: 1em;
   margin-left: var(--spacing-small);
   transition: var(--transition-time-x-fast);
   height: 2em
}


.navbar-item-icon-help {
   grid-column: 6;
   align-self: center;
   vertical-align: bottom;
}

.navbar-item-icon-help .icon {
   width: 1.20em;
   background-color: var(--navbar-color);
   mask-image: url(images/help.svg);
   mask-position: center;
   mask-repeat: no-repeat;
   mask-size: 1em;
   margin-left: var(--spacing-small);
   transition: var(--transition-time-x-fast);
   height: 2em
}


.navbar-item-session {
   grid-column: 5;
   align-self: center;
   justify-self: right;
}

.navbar-named-drop-down-item .settings-icon:hover {
   transition: var(--transition-time-x-fast);
   transform: scale(1.2) rotate(180deg);
}

.tk-input-drop-down-company {
   width: 15em;
}

.tk-input-drop-down-company .container {
   border: solid 1px transparent !important;
   border-radius: 0 !important;
}

.tk-input-drop-down-company .tk-input {
   color: var(--navbar-color) !important;
   background-color: var(--navbar-color-bg) !important;
 }

 .tk-input-drop-down-company .tk-input input {
    text-align: right;
 }

 .tk-input-drop-down-company .tk-input .icon-down {
    background-color: var(--navbar-color) !important;
 }


 .navbar-item-icon-user:hover > .user-menu {
    display: block;
 }

 .user-menu {
   position: relative;
   display: none;
 }

 .user-menu .container {
   position: absolute;
   top: 0em;
   right: 0;
   border: solid 1px var(--navbar-color-bg);
   border-radius: 5px;
   z-index: 100;
   padding: 0.25em;
   color: var(--navbar-color-bg);
   background-color: var(--navbar-color);
   box-shadow: 0 0 7px -2px #323232;
 }

 .user-menu .container ul,
 .user-menu .container li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding: 0.2em;
 }

 .user-menu .container ul:not(:last-child) {
   border-bottom: solid 1px var(--navbar-color-bg);
 }

 .user-menu .container li {
   padding: 0em;
}

 .user-menu .container li * {
   padding: 0.25em;
   margin-bottom: 0.1em;
   display: block;
 }

 .user-menu .container li a {
    color: var(--navbar-color-bg);
    text-decoration: none;
 }

 .user-menu .container li a:hover {
   background-color: #EFEFEF;
 }