.radar-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: left;
   align-content: flex-start;
   align-items: flex-start;

   width: 100vw;
   height: 100vh;
   box-sizing: border-box;

   background-color: var(--content-bg-color);
   color: var(--content-color);

   font-size: var(--font-size-sans-serif);
   font-family: var(--font-family-sans-serif);

   animation: radar-container-fade-in ease 0.5s;
   -webkit-animation: radar-container-fade-in ease 0.5s;
   -moz-animation: radar-container-fade-in ease 0.5s;
   -o-animation: radar-container-fade-in ease 0.5s;
   -ms-animation: radar-container-fade-in ease 0.5s;
}
