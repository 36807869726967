html, body {
   position: fixed;
   overflow: hidden;
   width: 100vw;
   height: 100vh;
   margin: 0;
   padding: 0;
   background-color: #090909;
}

body {
   margin: 0;
   font-family:
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
