@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-Italic.ttf") format("truetype");;
   font-style: italic;
}

@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-Light.ttf") format("truetype");;
   font-weight: lighter;
}

@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-Light.ttf") format("truetype");;
   font-weight: lighter;
   font-style: italic;
}

@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-Bold.ttf") format("truetype");;
   font-weight: bold;
}

@font-face {
   font-family: "Nunito";
   src: local("Nunito"), url("../../fonts/Nunito/Nunito-BoldItalic.ttf") format("truetype");;
   font-weight: bold;
   font-style: italic;
}
