@keyframes radar-container-fade-in {
   0% {opacity:0;}
   100% {opacity:1;}
}

@-moz-keyframes radar-container-fade-in {
   0% {opacity:0;}
   100% {opacity:1;}
}

@-webkit-keyframes radar-container-fade-in {
   0% {opacity:0;}
   100% {opacity:1;}
}

@-o-keyframes radar-container-fade-in {
   0% {opacity:0;}
   100% {opacity:1;}
}

@-ms-keyframes radar-container-fade-in {
   0% {opacity:0;}
   100% {opacity:1;}
}
